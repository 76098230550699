import { useEffect } from 'react';

const noop = () => {};

function Toggle({
  checkedLabel = '',
  unCheckedLabel = '',
  checkedChildren = '',
  unCheckedChildren = '',
  singleColor, // def => false
  onChange = noop,
  onClick = noop,
  checked = false,
  setChecked = () => {},
  disabled = false,
}) {
  useEffect(() => {
    onChange(checked);
  }, [checked, onChange]);

  function toggle(e) {
    if (!disabled) {
      const newChecked = !checked;
      setChecked(newChecked);
      onClick(newChecked, e);
    }
  }

  return (
    <>
      <div className="toggle">
        <span className="option">{checkedLabel}</span>
        <label
          className={`toggle-button ${checked ? 'checked' : ''}`}
          onClick={toggle}
        >
          {checkedChildren}
          <span
            className={`slider round${
              singleColor ? ' slider-single-color' : ''
            }`}
          ></span>
          {unCheckedChildren}
        </label>
        <span>{unCheckedLabel}</span>
      </div>
      <style>{`
        .toggle {
          display: flex;
          margin-top: var(--margin-md);
          margin-bottom: var(--margin-md);
          align-items: center;
          gap: 20px;
        }
        .toggle .option {
          font-size: 15px;
        }
        .toggle-button {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 26px;
        }
        .toggle-button  input { 
        opacity: 0;
        width: 0;
        height: 0;
        }
        .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        -webkit-transition: .4s;
        transition: .4s;
        }
        .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,.15);
        }
        :not(.checked) > .slider {
        }
        .slider-single-color {
          background-color: var(--accent);
        }
        toggle:hover .slider {
        box-shadow: var(--shadow-light);
        }
        .checked .slider {
         background-color: var(--accent);
        }
        .checked .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
        }
        .slider.round {
        border-radius: 50px;
        }
        .slider.round:before {
        border-radius: 50%;
        }
        .disabled {
          background: var(--disable-bg);
          color: var(--disabled); 
        } 
            `}</style>
    </>
  );
}

export default Toggle;
