import Icon from 'components/utils/Icon';
import OutsideClickHandler from 'components/utils/OutsideClickHandler';
import { useCallback, useEffect, useState } from 'react';
import request from 'tools/request';

function DropDown({
  options: propsOptions = [],
  placeholder = '',
  onChange = () => {},
  url,
  transformer = () => {},
  onClear = () => {},
  clearable = false,
  defaultValue,
  labelTransformer = (label) => label,
  className,
  parentformref,
  currentrefindex = 0,
  disabled = false,
  value,
  required = false,
  readOnly,
}) {
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const [selectedLabel, setSelectedLabel] = useState(() =>
    defaultValue && options?.length
      ? options.find((el) => el.value === defaultValue)?.label
      : ''
  );
  const [searchText, setSearchText] = useState('');
  const [focused, setFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOptions(propsOptions);
  }, [propsOptions]);

  useEffect(() => {
    if (selectedLabel === '') {
      setSelectedLabel(
        defaultValue && propsOptions?.length
          ? propsOptions.find((el) => el.value === defaultValue)?.label
          : ''
      );
    }
  }, [defaultValue, propsOptions, selectedLabel]);

  function getOptions(value) {
    setIsLoading(true);
    request(typeof url === 'string' ? `${url}?q=${value}` : url(value))
      .then((response) => {
        const data = response.data.map((el) => ({
          ...transformer(el),
          data: el,
        }));
        setOptions(data);
      })
      .finally(() => setIsLoading(false));
  }

  const filterOptions = useCallback(
    (value) => {
      const newOptions = propsOptions.filter((el) =>
        el.label.toLowerCase().includes(value.toLowerCase())
      );
      setOptions(newOptions);
    },
    [propsOptions]
  );

  const handleChange = useCallback(
    (value) => {
      setSelected(value);
      const option = options.find((option) => option.value === value);
      const selectedLabel = option?.label;
      setSelectedLabel(selectedLabel);
      setSearchText('');
      closeDropDown();
      onChange(value, selectedLabel, option.data || option);
    },
    [onChange, options]
  );

  const closeDropDown = () => setIsOpen(false);

  function search(e) {
    const value = e.target.value;
    setSearchText(value);

    if (url) {
      getOptions(value);
    } else {
      filterOptions(value);
    }
  }

  const handleFocus = () => {
    setFocused(true);
    setSearchText('');
    !url && setOptions(propsOptions);
  };

  function handleBlur() {
    setSearchText('');
    setFocused(false);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter' && options.length) {
      e.preventDefault();
      handleChange(options[0].value);
      e.target.blur();
    }
  }

  const handleClear = useCallback(() => {
    setSelectedLabel(null);
    setSelected(undefined);
    onClear();
    onChange(undefined, '', undefined);
  }, [onChange, onClear]);
  useEffect(() => {
    if (parentformref && !parentformref?.[currentrefindex]) {
      parentformref[currentrefindex] = {
        clear: handleClear,
      };
    }
    return () => {
      if (parentformref?.[currentrefindex])
        parentformref[currentrefindex] = null;
    };
  }, [currentrefindex, handleClear, parentformref]);
  return (
    <div>
      <div className={focused ? 'focused dropdown' : 'dropdown'}>
        <div className="input-drop">
          <span>
            <input
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              onChange={search}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              value={searchText}
              autoComplete="nope"
              className={className}
              disabled={disabled}
              required={value ? false : required}
              readOnly={readOnly}
            />
          </span>

          {!searchText && (
            <span
              className={`selected-content ${
                disabled ? 'selected-content-disabled' : ''
              }`}
            >
              {labelTransformer(selectedLabel || placeholder, selected)}
            </span>
          )}
          {!!(clearable && selected) && (
            <span className="clear-wrapper" onClick={handleClear}>
              <span className="clear">
                <Icon type="x" />
              </span>
            </span>
          )}
          {isLoading && <span className="loader" />}
        </div>
        <span
          className="icon-input"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Icon type="chevron-down" />
        </span>
        {isOpen && (
          <OutsideClickHandler onOutsideClick={closeDropDown}>
            <div className="drop-list">
              <div>
                {!!(options.length === 0) ? (
                  <div className="no-data">
                    <Icon
                      type="hard-drive"
                      style={{ fontSize: 25, padding: '5px 0px' }}
                    />
                    <p>No data</p>
                  </div>
                ) : (
                  <ul>
                    {options.map(({ label, value, disabled = false }) => (
                      <li
                        key={label}
                        onClick={() => !disabled && handleChange(value)}
                        className={`${disabled ? 'disabled' : ''}${
                          value === selected ? 'selected' : ''
                        }`}
                        value={value}
                      >
                        {label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>

      <style jsx>{`
        .focused .input-drop {
          border: solid thin var(--input-border);
          -webkit-transition: all 0.15s ease-in;
          transition: all 0.15s ease-in;
          -webkit-box-shadow: var(--shadow-input);
          box-shadow: var(--shadow-input);
        }

        .dropdown {
          position: relative;
          background: var(--background-utils);
          transition: all 0.3s ease;
        }

        .input-drop {
          position: relative;
          display: flex;
          width: 100%;
          border: 1px solid var(--grey-lighter);
          border-radius: 5px;
          height: 40px;
          transition: all 0.3s ease;
          cursor: auto;
        }

        .input-drop:hover {
          border: 1px solid var(--input-border);
          transition: all 0.3s ease;
        }

        .input-drop:focus {
          border: solid thin var(--input-border);
          -webkit-transition: all 0.15s ease-in;
          transition: all 0.15s ease-in;
          -webkit-box-shadow: var(--shadow-input);
          box-shadow: var(--shadow-input);
        }

        .input-drop span {
          position: absolute;
          top: 0;
          width: 100%;
          bottom: 0;
          display: flex;
          font-size: 16px;
          align-items: center;
        }

        .input-drop input {
          margin: 0;
          padding: 0 11px;
          background: 0 0;
          border: none;
          outline: none;
          box-shadow: none !important;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: 40px;
          z-index: 9;
          width: 100%;
          cursor: ${selected ? 'pointer' : 'text'};
          // opacity:${selected ? '0' : '1'};
        }

        .input-drop input.error {
          border: solid thin red;
        }

        .input-drop .selected-content {
          color: ${selected && !focused ? 'var(--foreground)' : 'gray'};
          padding: 0 10px;
        }

        .selected-content-disabled {
          color: var(--disabled) !important;
        }

        .icon-input {
          position: absolute;
          right: 10px;
          top: 10px;
          transform: rotate(0deg);
          transition: 0.3s ease;
          cursor: pointer;
        }

        .focused .icon-input {
          transform: rotate(180deg);
          transition: 0.3s ease;
        }

        .selected-item {
          font-weight: 400;
          color: var(--foreground);
          padding: 0 11px;
        }

        .drop-list {
          margin-top: 4px;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          font-feature-settings: 'tnum';
          position: absolute;
          z-index: 1050;
          box-sizing: border-box;
          overflow-y: auto;
          overflow-x: hidden;
          font-size: 14px;
          font-variant: initial;
          background-color: var(--background);
          border-radius: 2px;
          outline: none;
          box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
            0 9px 28px 8px #0000000d;
          width: 100%;
          transition: all 0.3s ease;
        }

        .drop-list > div {
          max-height: 300px;
          overflow-anchor: none;
          transition: all 0.3s ease;
        }

        .drop-list ul {
          margin: 0;
          padding: 0;
        }

        .drop-list ul li {
          padding: 10px 12px;
        }

        li.selected {
          background: #e6f7ff;
          color: black;
          font-weight: 600;
        }

        li.disabled {
          background: #ddd !important;
          cursor: default;
        }
        li.disabled:hover {
          background: var(#ddd) !important;
          cursor: default !important;
        }

        .drop-list ul li:hover {
          background: #dadbdc4f;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .input-drop .clear-wrapper {
          color: #444;
          width: 30px;
          right: 20px;
          font-size: 11px;
          top: 0px !important;
          position: absolute;
          z-index: 9;
          cursor: pointer;
        }

        .input-drop .clear-wrapper .clear {
          background: #bbb;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 100%;
          text-align: center;
          height: 16px;
          width: 16px;
          font-size: 14px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .input-drop .clear-wrapper .clear :global(.icon) {
          font-size: 14px;
        }

        .drop-list::-webkit-scrollbar {
          width: 0.4em;
          position: absolute;
        }

        .drop-list::-webkit-scrollbar-thumb {
          background-color: var(--accent);
          outline: 1px solid var(--accent);
          position: absolute;
        }

        .drop-list::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px rgb(0, 0, 0, 0.3);
          position: absolute;
        }

        .loader {
          top: 9px !important;
          opacity: 0.7;
          right: 30px !important;
          border: 2px solid var(--foreground);
          border-radius: 100%;
          border-top: 2px solid var(--background-utils);
          width: 20px !important;
          height: 20px !important;
          -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
        }

        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .no-data {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 0px;
          padding: 10px 0px;
          align-items: center;
          justify-content: center;
          opacity: 0.7;
        }

        .no-data span p {
          padding: 10px;
          margin: 0px;
          font-size: 14px;
        }

        @media only screen and (min-width: 768px) {
          .input-drop span {
            font-size: 15px;
          }
        }
      `}</style>
    </div>
  );
}

export default DropDown;
