import Uploader from 'components/utils/Uploader';
import { Field } from 'react-final-form';

import ReloadFieldProvider from '../ReloadFieldProvider';
import { printErrors, validate } from '../utils';

function File(props) {
  const {
    label,
    name,
    onStart = () => {},
    onRemove = () => {},
    onCancel = () => {},
    onComplete = () => {},
    onChange = () => {},
    ...otherProps
  } = props;

  return (
    <div>
      <Field name={name} validate={(...params) => validate(props, ...params)}>
        {({ input, meta }) =>
          (function () {
            const hasError = meta.error && meta.touched;
            return (
              <ReloadFieldProvider meta={meta} input={input}>
                <div className="form-item form-type-file">
                  <input {...input} type="hidden" />
                  <label>{label} </label>
                  <Uploader
                    onStart={onStart}
                    onRemove={() => {
                      input.onChange(null);
                      onRemove();
                    }}
                    onCancel={onCancel}
                    onChange={onChange}
                    onComplete={(response) => {
                      input.onChange(response._id);
                      onComplete(response);
                    }}
                    {...otherProps}
                    className={hasError ? 'error' : ''}
                    showImg
                  />
                  {hasError && printErrors(meta.error)}
                </div>
              </ReloadFieldProvider>
            );
          })()
        }
      </Field>
      <style jsx>{`
        .select-box {
          /* margin-bottom: 1em; */
        }
      `}</style>
    </div>
  );
}

export default File;
