import { Field } from 'react-final-form';

import { validate } from '../utils';

function Radio({
  label,
  name,
  options,
  onChange = () => {},
  labelClassName = '',
  selected,
  ...props
}) {
  function handleChange(a) {
    onChange(a.target.value);
  }

  return (
    <div>
      <label className="label">{label}</label>
      <div className={`radio-group`}>
        {options.map(({ label, value, disabled }) => (
          <label
            key={value}
            onChange={handleChange}
            className={`${labelClassName} ${
              (disabled || props.disabled) && 'radio-group-disabled'
            }
            ${selected && selected === value && 'selected-label'}`}
          >
            {(function () {
              const input = (
                <Field
                  name={name}
                  component="input"
                  type="radio"
                  value={value}
                  validate={(...params) => validate(props, ...params)}
                  {...props}
                />
              );

              return typeof label === 'function' ? (
                label(input)
              ) : (
                <>
                  {input}
                  {label}
                </>
              );
            })()}
          </label>
        ))}
      </div>

      <style jsx>{`
        label {
          display: block;
        }
        .radio-group-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .label {
          margin-bottom: 20px;
        }
        .selected-label {
          border: solid 2px var(--accent) !important;
          box-shadow: var(--shadow-light-hover);
        }
      `}</style>
    </div>
  );
}

export default Radio;
