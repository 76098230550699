import { Field as RFFField } from 'react-final-form';
import BaseCheckbox from 'components/utils/Checkbox';
import { validate } from 'components/utils/form/utils';
function Checkbox({
  label,
  name,
  checked,
  onChange = () => {},
  defaultValue = false,
  ...props
}) {
  return (
    <>
      <div>
        <RFFField
          name={name}
          component="input"
          type="checkbox"
          validate={(...params) => validate(props, ...params)}
          defaultValue={defaultValue}
        >
          {({ input }) => (
            <BaseCheckbox
              {...input}
              checked={checked}
              onChange={(e) => {
                input.onChange(e);
                onChange(e);
              }}
              label={label}
              name={name}
            />
          )}
        </RFFField>
      </div>
      <style jsx>{`
        input {
          vertical-align: middle;
        }
        label {
          margin-left: 5px;
        }
      `}</style>
    </>
  );
}

export default Checkbox;
