import { FormSpy } from 'react-final-form';
import Button from 'components/utils/Button';

function getValue(obj, key) {
  const splitted = key.split('.');

  if (splitted.length === 1) {
    return obj?.[key];
  } else {
    const newObj = obj[splitted[0]];
    splitted.shift();
    const newKey = splitted.join('.');
    return getValue(newObj, newKey);
  }
}

function Submit({
  children,
  label = 'Submit',
  className,
  disableOnPristine,
  disableOnValidation,
  disabled,
  ...otherProps
}) {
  function checkDisabled(props) {
    if (disabled) {
      return true;
    }
    if (disableOnValidation === true) {
      return props.hasValidationErrors;
    }

    if (Array.isArray(disableOnValidation)) {
      for (const field of disableOnValidation) {
        if (getValue(props.errors, field)) {
          return true;
        }
      }
    }

    if (disableOnPristine) {
      return props.submitting || props.pristine;
    }
  }
  return (
    <FormSpy>
      {(props) => (
        <Button
          type="submit"
          disabled={checkDisabled(props)}
          className={className}
          {...otherProps}
        >
          {children || label}
        </Button>
      )}
    </FormSpy>
  );
}

export default Submit;
