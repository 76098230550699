import Button from 'components/utils/Button';
import { FormSpy } from 'react-final-form';

function Reset({ label = 'Reset', className = '', onClick = null }) {
  return (
    <FormSpy>
      {(props) => (
        <Button
          label={label}
          className={className}
          disabled={!Object.keys(props.values).length}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              props.form.initialize({}) || props.form.restart();
            }
          }}
        />
      )}
    </FormSpy>
  );
}

export default Reset;
