import { Field as RFFField } from 'react-final-form';

import { printErrors, validate } from './utils';
import useTranslation from '../../../hooks/useTranslation';

function Field(props) {
  const {
    name,
    label,
    type,
    startAdornment,
    endAdornment,
    onChange = () => {},
    initialValue,
    Button,
    ...otherProps
  } = props;
  const { TRANSLATIONS, translationsParameters } = useTranslation();
  return (
    <div
      className={`form-item ${
        Button && 'form-item-button'
      } form-item-type-${type}`}
    >
      <RFFField
        name={name}
        validate={(...params) =>
          validate(TRANSLATIONS, translationsParameters, props, ...params)
        }
        className="input-class"
        initialValue={initialValue}
      >
        {({ input, meta }) =>
          (function () {
            const hasError = meta.error && meta.touched;
            return (
              <div className={`${Button && 'text-container'}`}>
                <div>
                  <label>{label}</label>
                  {startAdornment && (
                    <div
                      className={`start-adornment${
                        label?.length ? ' adornment-with-label' : ''
                      }`}
                    >
                      {startAdornment}
                    </div>
                  )}
                  <input
                    autoCapitalize="none"
                    {...input}
                    type={type}
                    onChange={(e) => {
                      input.onChange(e);
                      onChange(e);
                    }}
                    {...otherProps}
                    className={hasError ? 'error' : ''}
                  />
                  {endAdornment && (
                    <div
                      className={`end-adornment${
                        label?.length ? ' adornment-with-label' : ''
                      }`}
                    >
                      {endAdornment}
                    </div>
                  )}
                  {hasError && printErrors(meta.error)}
                </div>
                <div>
                  {Button && <div className="button-container">{Button}</div>}
                </div>
              </div>
            );
          })()
        }
      </RFFField>
      <style jsx>
        {`
          .input-class {
            border: solid thin red;
          }

          .error {
            border: solid thin red;
          }

          .form-item {
            position: relative;
          }

          .form-item-button input {
            border-radius: 8px 0 0 8px !important;
          }

          .start-adornment {
            position: absolute;
            top: calc(50% - 10px);
            left: 0.8rem;
            cursor: pointer;
          }

          .text-container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
          }

          .end-adornment {
            position: absolute;
            top: calc(50% - 10px);
            right: 0.8rem;
            cursor: pointer;
          }
          .adornment-with-label {
            top: 2.25rem;
          }

          .button-container {
          }
        `}
      </style>
    </div>
  );
}

export default Field;
