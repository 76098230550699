import Field from 'components/utils/form/Field';

function Text(props) {
  return (
    <div style={{ width: '100%' }}>
      <Field {...props} />
    </div>
  );
}

export default Text;
