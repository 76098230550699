function Checkbox({ label, name, checked, onChange = () => {}, ...props }) {
  // useEffect(() => {
  //   if (parentformref && !parentformref?.[currentrefindex]) {
  //     parentformref[currentrefindex] = {
  //       clear: () => {
  //         onChange(false);
  //       },
  //     };
  //   }
  //   return () => {
  //     if (parentformref?.[currentrefindex])
  //       parentformref[currentrefindex] = null;
  //   };
  // }, [currentrefindex, onChange, parentformref]);
  return (
    <>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      {label && <label htmlFor={name}>{label}</label>}
      <style jsx>{`
        input {
          vertical-align: middle;
        }
        label {
          margin-left: 10px;
          vertical-align: middle;
        }
      `}</style>
    </>
  );
}

export default Checkbox;
