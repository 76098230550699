import { Field } from 'react-final-form';

function Hidden({ name, value }) {
  return (
    <Field name={name} initialValue={value}>
      {({ input }) => <input {...input} type="hidden" />}
    </Field>
  );
}

export default Hidden;
