import React, { useEffect, useState } from 'react';

export default function ReloadFieldProvider({ children, input, meta }) {
  const generateKey = () => `${input.name}-${Date.now()}`;

  const [key, setKey] = useState(generateKey());

  useEffect(() => {
    meta.pristine && setKey(generateKey());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.pristine]);

  return <React.Fragment key={key}>{children}</React.Fragment>;
}
