import { isEmpty } from 'tools/array';
import React from 'react';

export function validateEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function validate(
  TRANSLATIONS,
  translationsParameters,
  props,
  value,
  values,
  input
) {
  const rules = generateRules(props, TRANSLATIONS, translationsParameters);

  return generateErrors(rules, value, values, input);
}

export function generateErrors(rules, value) {
  const errors = [];

  rules.forEach((rule) => {
    if (rule.required && !value) {
      errors.push(rule.message);
    }

    if (rule.email && !validateEmail(value)) {
      errors.push(rule.message);
    }

    if (rule.len && value && rule.len !== value.length) {
      errors.push(rule.message);
    }

    if (rule.min && value && rule.min > value.length) {
      errors.push(rule.message);
    }

    if (rule.max && value && rule.max < value.length) {
      errors.push(rule.message);
    }

    if (rule.minValue && value && Number(value) < Number(rule.minValue)) {
      errors.push(rule.message);
    }

    if (rule.maxAmount && value && Number(value) > Number(rule.maxAmount)) {
      errors.push(rule.message);
    }

    if (rule.pattern && value) {
      const regex = new RegExp(rule.pattern);
      !regex.test(value) && errors.push(rule.message);
    }
  });

  if (isEmpty(errors)) {
    return undefined;
  }

  return errors;
}

export function generateRules(params, TRANSLATIONS, translationsParameters) {
  const {
    rules: origRules = [],
    name,
    label,
    placeholder,
    required,
    type,
    len,
    min,
    max,
    pattern,
    onlyNumbers,
    minValue,
    maxAmount,
  } = params;

  const rules = [...origRules];

  const fieldName = label || placeholder || name;

  if (required) {
    rules.push({
      required,
      message: translationsParameters(TRANSLATIONS.formErrors.required, [
        <span key={fieldName}>{fieldName}</span>,
      ]),
    });
  }

  if (type === 'email') {
    rules.push({
      email: true,
      message: translationsParameters(TRANSLATIONS.formErrors.validEmail, [
        <span key={fieldName}>{fieldName}</span>,
      ]),
    });
  }

  if (len) {
    rules.push({
      len,
      message: translationsParameters(TRANSLATIONS.formErrors.length, [
        <span key={fieldName}>{fieldName}</span>,
        <span key={len}>{len}</span>,
      ]),
    });
  }

  if (min) {
    rules.push({
      min,
      message: translationsParameters(TRANSLATIONS.formErrors.minLength, [
        <span key={fieldName}>{fieldName}</span>,
        <span key={min}>{min}</span>,
      ]),
    });
  }

  if (minValue) {
    rules.push({
      minValue,
      message: translationsParameters(TRANSLATIONS.formErrors.minValue, [
        <span key={fieldName}>{fieldName}</span>,
        <span key={minValue}>{minValue}</span>,
      ]),
    });
  }

  if (max) {
    rules.push({
      max,
      message: translationsParameters(TRANSLATIONS.formErrors.maxLength, [
        <span key={fieldName}>{fieldName}</span>,
        <span key={max}>{max}</span>,
      ]),
    });
  }

  if (pattern) {
    rules.push({
      pattern,
      message: translationsParameters(TRANSLATIONS.formErrors.invalid, [
        <span key={fieldName}>{fieldName}</span>,
      ]),
    });
  }

  if (onlyNumbers) {
    rules.push({
      pattern: /^\d+$/,
      message: translationsParameters(TRANSLATIONS.formErrors.onlyNumber, [
        <span key={fieldName}>{fieldName}</span>,
      ]),
    });
  }

  if (maxAmount) {
    rules.push({
      maxAmount,
      message: translationsParameters(TRANSLATIONS.formErrors.maxAmount, [
        <span key={fieldName}>{fieldName}</span>,
      ]),
    });
  }

  return rules;
}

export function printErrors(errors) {
  return (
    <span>
      <ul>
        {errors.map((error) => (
          <li className="error-span" key={error}>
            {error}
          </li>
        ))}
      </ul>
    </span>
  );
}
