import Toggle from 'components/utils/Toggle';
import { Field } from 'react-final-form';

function Switch({ name, onChange = () => {}, disabled = false, ...props }) {
  return (
    <>
      <div className={disabled && 'disabled-switch'}>
        <Field name={name}>
          {({ input }) => (
            <div>
              <input {...input} type="hidden" />
              <Toggle
                onChange={(value) => {
                  input.onChange(value);
                  onChange(value);
                }}
                {...props}
                disabled={disabled}
              />
            </div>
          )}
        </Field>
      </div>
      <style jsx>
        {`
          .disabled-switch {
            opacity: 0.5;
          }
          .disabled-switch :global(.slider) {
            cursor: initial;
          }
        `}
      </style>
    </>
  );
}

export default Switch;
