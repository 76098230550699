import DropDown from 'components/utils/DropDown';
import { Field } from 'react-final-form';

import ReloadFieldProvider from '../ReloadFieldProvider';
import { printErrors, validate } from '../utils';

function Select(props) {
  const {
    label,
    name,
    options,
    placeholder,
    defaultValue,
    onChange = () => {},
    ...otherProps
  } = props;

  return (
    <div className="select-box">
      <Field
        name={name}
        validate={(...params) => validate(props, ...params)}
        defaultValue={defaultValue}
        {...otherProps}
      >
        {({ input, meta }) =>
          (function () {
            const hasError = meta.error && meta.touched;
            return (
              <ReloadFieldProvider input={input} meta={meta}>
                <div className="form-item">
                  <input {...input} type="hidden" />
                  {label && <label>{label} </label>}
                  <DropDown
                    options={options}
                    placeholder={placeholder}
                    defaultValue={meta.initial || defaultValue}
                    onChange={(value, label, data) => {
                      input.onChange(value);
                      onChange(value, label, data);
                    }}
                    {...otherProps}
                    className={hasError ? 'error' : ''}
                    value={input?.value}
                  />
                  {hasError && printErrors(meta.error)}
                </div>
              </ReloadFieldProvider>
            );
          })()
        }
      </Field>
      <style jsx>{`
        .select-box {
          /*  margin-bottom: 1em; */
        }
      `}</style>
    </div>
  );
}

export default Select;
