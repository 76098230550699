import { useEffect } from 'react';
import { useState } from 'react';

export default function ProgressBar({ progressPercent }) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(progressPercent);
  }, [progressPercent]);

  return (
    <div className="progress-bar">
      <div className="progress-value"> </div>
      <style jsx>{`
        .progress-bar {
          background: #e6e6e6;
          justify-content: flex-start;
          border-radius: 100px;
          align-items: center;
          position: relative;
          padding: 0 5px;
          display: flex;
          height: 10px;
          width: 100%;
          min-width: 100px;
        }
        .progress-value {
          width: ${percent}%;
          box-shadow: 0 10px 40px -10px var(--accent);
          border-radius: 100px;
          background: var(--accent);
          height: 8px;
          transition: 0.3s;
        }
        .progress-bar:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          box-sizing: border-box;
          background-image: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 10px,
            #000 10px,
            #000 20px
          );
          opacity: 0.3;
          mix-blend-mode: overlay;
          border-radius: 50px;
          // opacity: 0;
        }
      `}</style>
    </div>
  );
}
