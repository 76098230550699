import { Form as RFFForm } from 'react-final-form';
import { Children, cloneElement, isValidElement, useRef } from 'react';

function Form({
  children: _children,
  initialValues = {},
  onSubmit = () => {},
  onChange = () => {},
  formRef = null,
}) {
  const refs = useRef(Children.map(_children, () => null));
  const children = Children.count(_children)
    ? Children.map(_children, (child, index) =>
        isValidElement(child)
          ? cloneElement(child, {
              parentformref: refs.current,
              currentrefindex: index,
            })
          : child
      )
    : _children;
  return (
    <RFFForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={onChange}
      render={(formApi) => {
        if (formRef && !formRef?.current) {
          // OVERWRITING RESET FUNCTION FOR CUSTOM COMPONENT
          const reset = (params) => {
            Children.forEach(children, (child, index) => {
              if (refs?.current?.[index]?.clear) {
                refs?.current?.[index]?.clear();
              }
            });
            formApi.form.reset(params);
          };
          formRef.current = { ...formApi.form, reset };
        }

        return (
          <form onSubmit={formApi.handleSubmit}>
            {typeof children === 'function' ? children(formApi) : children}
          </form>
        );
      }}
    />
  );
}

export default Form;
