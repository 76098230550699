import Field from 'components/utils/form/Field';

function Password({ ...props }) {
  return (
    <div>
      <Field type="password" {...props} />
    </div>
  );
}

export default Password;
