import { Field } from 'react-final-form';

function Textarea({
  name,
  label,
  validate,
  onChange = () => {},
  defaultValue = '',
  ...props
}) {
  return (
    <div>
      <Field
        name={name}
        validate={validate}
        className="textarea"
        component="textarea"
        defaultValue={defaultValue}
      >
        {({ input, meta }) => (
          <div>
            {label && <label>{label} </label>}
            <textarea
              {...input}
              onChange={(e) => {
                input.onChange(e);
                onChange(e);
              }}
              {...props}
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
      <style jsx>{`
        textarea {
          width: 100%;
          min-height: 8em;
          border: 1px solid #d5d5d5;
          border-radius: 10px;
          background: var(--background);
          color: var(--foreground);
          font-size: 16px;
          padding: 8px;
        }

        textarea {
          margin-top: 10px;
          border: 1px solid var(--grey-lighter);
          border-radius: 5px;
          background: var(--background-utils);
        }
      `}</style>
    </div>
  );
}

export default Textarea;
