import Icon from 'components/utils/Icon';
import ProgressBar from 'components/utils/ProgressBar';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { BASIC_URL } from 'tools/constants';
import { getToken } from 'tools/utils';

export default function Uploader({
  className,
  onStart = () => {},
  onRemove = () => {},
  onCancel = () => {},
  onComplete = () => {},
  onChange = () => {},
  componentRef = null,
  showImg,
}) {
  const { TRANSLATIONS } = useTranslation();
  const [percentage, setPercentage] = useState(1);
  const [file, setFile] = useState({});
  const [response, setResponse] = useState();
  const [status, setStatus] = useState('ready');

  useEffect(() => {
    onChange(status, file, response, percentage);
  }, [status, file, response, percentage, onChange]);

  function upload(e) {
    setFile(e.target.files[0]);

    const data = new FormData();
    data.append('files', e.target.files[0]);

    const request = new XMLHttpRequest();
    request.open('POST', `${BASIC_URL}/api/user_file/upload`);
    request.setRequestHeader('Authorization', `Bearer ${getToken()}`);

    request.upload.addEventListener('loadstart', function () {
      onStart();
      setStatus('progressing');
    });

    request.upload.addEventListener('progress', function (e) {
      let percentComplete = (e.loaded / e.total) * 100;
      setPercentage(percentComplete.toFixed(0));
    });

    request.addEventListener('load', function () {
      const response = JSON.parse(request.response);
      setStatus('completed');
      setResponse(response);
      onComplete(response);
    });

    request.send(data);
  }

  const handleRemove = useCallback(() => {
    setFile({});
    setResponse();
    setStatus('ready');
    onRemove();
  }, [onRemove]);

  function handleCancel() {
    // request.abort()
    setStatus('ready');
    setFile({});
    onCancel();
  }

  function isImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file['type']);
  }

  useEffect(() => {
    if (componentRef && !componentRef?.current) {
      componentRef.current = { handleRemove };
    }
  }, [handleRemove, componentRef]);
  return (
    <>
      <div className={`uploader ${className || ''}`}>
        {showImg && (
          <div className="image-wrapper">
            <div
              className="image"
              style={{
                backgroundImage: `url(${
                  response?.url && isImage(file) ? response.url : ''
                })`,
              }}
            >
              {!isImage(file) && <Icon type="image" />}
            </div>
          </div>
        )}
        <div className="detail">
          {status === 'ready' && (
            <div className="ready">
              <label htmlFor="file-upload" className="upload-button">
                <Icon type="upload" />
                {TRANSLATIONS.products.edit.upload}
              </label>
              <input id="file-upload" type="file" onChange={upload} />
            </div>
          )}
          {status === 'progressing' && (
            <div className="progressing">
              <div className="name-wrapper">{file.name}</div>
              <div className="proggres-wrapper">
                <ProgressBar progressPercent={percentage} />
              </div>
              <div className="cancel-wrapper" onClick={handleCancel}>
                <Icon type="x" />
              </div>
            </div>
          )}
          {status === 'completed' && (
            <div className="completed">
              <div className="name-wrapper">{file.name}</div>
              <div className="cancel-wrapper" onClick={handleRemove}>
                <Icon type="x" />
              </div>
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .uploader {
          width: 100%;
          padding: ${showImg ? '5px' : 'var(--margin-xl)'};
          display: flex;
          position: relative;
          border: 1px dashed #2271b1;
          border-radius: 5px;
          background: rgba(0, 95, 211, 0.05);
          align-items: center;
        }
        .error {
          border: 1px dashed red;
          margin-bottom: 1em;
        }
        .image-wrapper {
          flex: 0 0 60px;
        }
        .image {
          width: 60px;
          height: 60px;
          background: #f4f4f4;
          border: 1px solid #ccc;
          color: #999;
          text-align: center;
          font-size: 2em;
          background-size: cover;
          background-position: center;
        }
        .detail {
          padding-left: 1em;
          flex: 1 1 100%;
        }
        .ready {
        }
        .progressing {
        }
        .name-wrapper {
          margin-bottom: 0.5em;
        }
        .cancel-wrapper :global(.icon) {
          position: absolute;
          top: 0;
          right: 0;
          width: 25px;
          line-height: 25px;
          background: #fff;
          text-align: center;
          cursor: pointer;
        }
        .proggres-wrapper {
        }
        input[type='file'] {
          display: none;
        }
        .upload-button {
          cursor: pointer;
          padding: 0.6em;
          color: var(--foreground);
          font-weight: 600;
          margin-bottom: 0px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2271b1;
          border-radius: var(--radius);
          border: solid thin #2271b1;
          background: #f6f7f7;
        }
        .upload-button :global(.icon) {
          margin-right: 0.5em;
        }
      `}</style>
    </>
  );
}
